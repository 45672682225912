<template>
    <div>
        <div id="heade-action" class="w-100 bg-white sticky-top p-3" style="align-items:center;justify-content:center;">
            <div>
                <img :src="$api.ste.logo" class="logo"/>
            </div>
            <div class="ml-auto">                
            </div>
            <div class="ml-auto" v-if="user != false">                
                <b-button class="ml-2" size="sm" :variant="call_color" :href="`tel:${user.telephone}`">
                    <i class="fas fa-phone"></i><br/>
                    <span class="h6">Appelez {{ user.prenom }} {{ user.nom[0] }}
                    </span>
                </b-button>
            </div>
        </div>
        <b-container v-if="ready"  class="mt-3" style="margin-bottom:50px">            
            <b-row v-if="!send" class="justify-content-center">
                <b-col cols="12" v-if="user != false">
                    <div class="p-2 text-center mt-5 mb-5">
                        <span class="text-white h2">Prendre rendez-vous avec {{ user.prenom }} {{ user.nom }}</span>
                    </div>
                </b-col>
                <b-col lg="9" md="11">  
                    <b-row class="" align-v="center">
                        <b-col md="9">
                            <div class="p-2 bg-white rounded">
                                <b-calendar 
                                    start-weekday="1"                           
                                    block
                                    :date-disabled-fn="dateDisabled"
                                    selected-variant="light-primary"
                                    today-variant="danger"
                                    nav-button-variant="danger"
                                    :min="min_date"
                                    label-no-date-selected="Pas de date sélectionné"
                                    v-model="date"
                                ></b-calendar>
                            </div>
                        </b-col>
                        <b-col md="3" class="text-center mt-5 mt-sm-0">
                            <div class="p-2 bg-white rounded d-block">
                                <b-time
                                    minutes-step="15"
                                    label-no-time-selected="Pas d'heure sélectionné"
                                    v-model="heure"
                                ></b-time>
                            </div>
                        </b-col>
                        <b-col cols="12" v-if="date != null && heure != null">
                            <div class="p-2 text-center mt-10 mb-5">
                                <span class="text-white h2">Votre rendez-vous sera le {{ $api.moment(date).format('DD/MM/YYYY') }} à {{ formatHeure(heure) }}</span><br/>
                                <b-button variant="light-danger" class="mt-3" @click="save()">Confirmer</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row v-else class="justify-content-center mt-20">
                <b-col cols="12">
                    <div class="text-center">
                        <i class="fas fa-check-circle fa-5x text-white"></i>
                    </div>
                    <div class="p-2 text-center mt-5">
                        <span class="text-white h2">Votre rendez-vous a bien été enregistré</span>
                    </div>
                </b-col>
            </b-row>
        </b-container>                
        <call-us-selector v-if="user != false && user.pbx_chat != null" ref="pbx" phonesystem-url="https://assureur-francais.3cx.eu" :party="user.pbx_chat"></call-us-selector>
    </div>
</template>
<script>    
    export default {
        name: "RDV",
        components: {},
        computed: {},
        data() {
            return {
                ready: false,                
                user:false,
                call_color: 'primary',
                min_date: this.$api.moment().format('YYYY-MM-DD'),
                date: null,
                heure: null,
                send: false
            }
        },
        beforeMount(){            
        },
        mounted() {  
            this.$api.ajax('/agenda/token/'+this.$route.params.token, null, res => {
                if(res.status === true){
                    this.user = res.data.user;
                }
            });
            setInterval(() => {
                this.call_color = this.call_color === 'primary' ? 'light-danger' : 'primary';
            },2000)                              
            this.ready = true;           
        },        
        methods: {            
            dateDisabled(ymd, date) {                
                const weekday = date.getDay()            
                return weekday === 0 || weekday === 6
            },
            formatHeure(h){
                if(h === null){
                    return null;
                }
                var tmp = h.split(':');
                return tmp[0]+":"+tmp[1];
            },
            save(){
                var params = {
                    token: this.$route.params.token,
                    date: this.date,
                    heure: this.formatHeure(this.heure)
                }
                this.$api.ajax('/agenda/token', params, res => {
                    if(res.status === true){
                        this.send = true;
                    }
                })
            }
        },        
    }        
</script>
<style lang="scss">    
    .logo {
        max-width: 250px;
        
    }        
    #heade-action {
        display: flex;
    }
    @media (max-width: 768px){
        #heade-action {
            display: block;
            text-align: center;
        } 
        #heade-action div {
            margin-bottom: 10px;
        }
        #heade-action .btn {
            padding: 0.50rem 0.70rem;
            font-size: 0.925rem;
            line-height: 1.35;
            border-radius: 0.85rem;
        }                
    }            
</style>